  function activeCards(){
    var cards = document.getElementsByClassName("card-card");
    for (var i=0; i< cards.length; i++) {
        cards[i].addEventListener('click', function () {
            window.location = this.getElementsByTagName("a")[0].href;
        });
    }
  }
  
  if (document.readyState === 'loading') {  // Loading hasn't finished yet
    document.addEventListener('DOMContentLoaded', activeCards());
  } else {  // `DOMContentLoaded` has already fired
    activeCards();
  }
